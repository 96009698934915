import React, { useEffect, useState } from 'react';
import { queryStringToCookies } from 'utils/urlHelper';
import useGenerateBreadcrumbs from 'utils/getBreadcrumbData';
import WebsiteLayout from 'layouts/websiteLayout';
import SEO, { createSEO } from 'components/Core/SEO';

const PageWrapper = (props) => {
  const { seo, components } = props?.data?.contentfulPage;
  const { children, queryParams, websiteLayoutProps } = props;
  const [industryLayout, setIndustryLayout] = useState(false);

  useEffect(() => {
    queryStringToCookies(queryParams);
  }, [queryParams]);

  useEffect(() => {
    const industryLayouts = ['Feature', 'Industry', 'Legal'];
    if (industryLayouts.includes(seo.pageCategory)) {
      setIndustryLayout(true);
    }
  }, [seo]);

  const hero =
    components?.find((ele) => ele.__typename === 'ContentfulDynamicHero') ||
    components?.find((ele) => ele.__typename === 'ContentfulSection' && ele.isHero) ||
    components?.find((ele) => ele.__typename === 'ContentfulLayout' && ele.template?.isHero);
  const header =
    components?.find((ele) => ele.__typename === 'ContentfulHeader' && !ele.isAnchorMenu) || {};
  const anchorLinkNavigation = components?.find(
    (ele) => ele.__typename === 'ContentfulHeader' && ele.isAnchorMenu,
  );
  const footer = components?.find((ele) => ele.__typename === 'ContentfulFooter') || {};
  const breadcrumbs = useGenerateBreadcrumbs(seo, hero);
  const faq = components?.find(
    (ele) =>
      ele.__typename === 'ContentfulAccordion' &&
      ele.components.every((x) => x.__typename === 'ContentfulFaq'),
  );

  const pageSeo = createSEO(seo, props, faq);

  return (
    <WebsiteLayout
      breadcrumbs={breadcrumbs}
      header={header}
      hero={hero}
      footer={footer}
      anchorLinkNavigation={anchorLinkNavigation}
      layout={industryLayout ? 'industry' : seo?.pageCategory}
      {...websiteLayoutProps}
    >
      <SEO {...pageSeo} />
      {children}
    </WebsiteLayout>
  );
};

export default PageWrapper;
