import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { subNavData } from 'components/Page/MarketingPro/data/subNavData';
import accordionPanelBg from 'components/Page/MarketingPro/images/accordion-panel-bg.png';
import SubNav from 'components/Page/MarketingPro/MarketingProSubNav';
import useBuildPageComponents from 'utils/useBuildPageComponents';
import { usePromoBanner } from 'hooks/useHeaderHeight';
import PageWrapper from './websitePage/PageWrapper';

const MarketingProDetailNewPage = (props) => {
  const promoHeight = usePromoBanner(true);
  const renderSubNav = () => (
    <SubNav data={subNavData} form={props.data.contentfulInlineMarketingForm} />
  );

  return (
    <Container
      $customCss={props?.data?.contentfulPage?.styles?.customStyling?.customStyling}
      promoHeight={promoHeight}
    >
      <PageWrapper {...props} websiteLayoutProps={{ layout: 'marketingPro', SubNav: renderSubNav }}>
        {useBuildPageComponents(props)}
      </PageWrapper>
    </Container>
  );
};

export default MarketingProDetailNewPage;

export const MarketingProDetailNewPageQuery = graphql`
  query MarketingProDetailNewPageQuery($pageUrl: String!) {
    contentfulPage(seo: { pageUrl: { eq: $pageUrl } }) {
      ...Page
    }
    contentfulInlineMarketingForm(contentful_id: { eq: "6DAZfo8POZVhudazUjAIxd" }) {
      ...InlineMarketingForm
    }
  }
`;

const Container = styled.div`
  .header-container {
    .website-header {
      &.scroll-down.shrink-nav {
        .mobile-header {
          max-height: 0 !important;
          overflow: hidden;
        }
        .website-header--inner-container {
          height: 0 !important;
        }
      }

      &.mobile-menu-opened {
        .toolbox-sticky-subnav {
          display: none;
        }
      }
    }
  }
  .website-header--main-menu {
    background: var(--white);
  }
  .section-layout--inner-container {
    padding: 135px 0 100px 0;

    @media (max-width: 991px) {
      padding: 135px 28px 100px 18px;
    }

    @media (max-width: 575px) {
      padding: 70px 28px 100px 18px;
    }
  }
  .data-layer-form {
    @media (min-width: 991px) {
      max-width: 520px;
    }
  }
  .website-header--inner-container {
    background: var(--white);
  }
  main .signup-form--submit-button {
    background: var(--titan-blue-3);

    &:hover {
      background: var(--titan-blue-4);
    }
  }
  .marketing-pro-hero--kicker-badge {
    background: var(--titan-blue-3);
    border-radius: 50px;
    padding: 5px 10px;
    color: var(--white);
    font-size: 13px;
    font-weight: 800;
    width: max-content;
    margin-bottom: 15px;

    @media (max-width: 991px) {
      margin: 0 auto 15px auto;
    }
  }
  .hero {
    @media (max-width: 1599px) {
      background-position-x: 0;
    }

    @media (max-width: 1199px) {
      background-position-x: -200px;
    }

    @media (max-width: 991px) {
      background-position-x: -300px;
    }

    @media (max-width: 575px) {
      background-position-x: -700px;
    }
  }
  .in-page-features--navigation-item-title,
  .section-software--title,
  .in-page-features--description-content-description,
  .section-software--navigation-item h2,
  .section-software--description-content-description,
  .in-page-features-v3--featured-content-description {
    color: var(--white) !important;
  }

  .selected-item h2:not(.font-p),
  .slick-current h2:not(.font-p) {
    color: var(--titan-blue-3) !important;
  }

  .section-software--main-content-wrapper {
    padding: 0 1px;
  }

  .section-software--main-content {
    background: linear-gradient(90deg, #434244 0%, var(--darkest) 100%) !important;
  }

  .toggle {
    background: var(--darkest);

    label {
      background: var(--dark);
    }

    .toggle--title-container {
      p {
        color: var(--white);
      }

      &.selected {
        p {
          color: var(--darkest);
        }
      }
    }

    h2 {
      max-width: 950px;
      color: var(--white);
      margin: 0 auto;
    }

    h4 {
      color: var(--white);
    }
  }

  .slick-next,
  .slick-prev {
    background-color: var(--white) !important;

    &:hover {
      background-color: var(--white) !important;
    }
  }

  .section-faq--container .section-faq--title {
    color: var(--white);
  }

  .MuiAccordion-root {
    background: var(--darkest);
    border: none !important;

    .MuiAccordionSummary-root {
      background: url('${accordionPanelBg}');
      background-size: 103%;
      background-position-x: -5px;

      .MuiAccordionSummary-content {
        h3 {
          color: var(--white);
        }
      }
    }

    .MuiAccordionDetails-root {
      background: var(--dark);

      p {
        color: var(--white);
        margin-top: 20px;
      }
    }

    @media (max-width: 810px) {
      .MuiAccordionSummary-root {
        background-size: 375%;
        background-position-x: -10px;
      }
    }
  }

  .marketing-pro-hero--kicker-badge {
    background: var(--titan-blue-3);
    border-radius: 50px;
    padding: 5px 10px;
    color: var(--white);
    font-size: 13px;
    font-weight: 800;
    width: max-content;
    margin-bottom: 15px;

    @media (max-width: 991px) {
      margin: 0 auto 15px auto;
    }
  }
  .hero {
    @media (max-width: 1599px) {
      background-position-x: 0;
    }

    @media (max-width: 1199px) {
      background-position-x: -200px;
    }

    @media (max-width: 991px) {
      background-position-x: -300px;
    }

    @media (max-width: 575px) {
      background-position-x: -700px;
    }
  }
  .in-page-features--navigation-item-title,
  .section-software--title,
  .in-page-features--description-content-description,
  .section-software--navigation-item h2,
  .section-software--description-content-description {
    color: var(--white) !important;
  }

  .selected-item h2:not(.font-p),
  .slick-current h2:not(.font-p) {
    color: var(--titan-blue-3) !important;
  }
  ${({ $customCss }) => $customCss};
`;
